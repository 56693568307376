import axios from 'axios';
import moment from 'moment';
import router from '@/routes';
import helpers from '@/helpers'
import lodash from 'lodash';
import dayjs from 'dayjs';

const state = {
    trips: null,
    trips_timing: null,
    transactions: null,
    tmw_transactions: null,
    tripsLoading: false,
    transaction_types: null,
    trip_timing_summary: null,
    loading_dltime_summary: false,
    order_topup_transaction_types: [
        { value: 'claim_error_delivery' },
        { value: 'claim_error_food' },
        { value: 'claim_error_delivery_manual_order' },
        { value: 'claim_error_delivery_zero_distance' },
        { value: 'claim_error_delivery_adjust' },
    ],
    manual_topup_transaction_types: [
        {value: 'claim_other'},
        {value: 'claim_error_hourly_fee'},
        {value: 'claim_mfg'},
    ],
    ln_hook_status:[
        { value: "DELIVEREDNOTIFICATION" },
        { value: "NOTDELIVEREDNOTIFICATION" },
        { value: "ORDERCREATIONNOTIFICATION" },
        { value: "ORDERALLOCATIONSTOP" },
        { value: "PICKEDUPNOTIFICATION" },
        { value: "NOTPICKEDUPNOTIFICATION" },
        { value: "ORDERUPDATENOTIFICATION" },
        { value: "ORDER ACCEPTED" },
        { value: "CANCELLEDNOTIFICATION" }
    ]
}

const getters = {
    tripsReport: (state) => state.trips,
    tripsTimingReport: (state) => state.trips_timing,
    tripsReportLoading: (state) => state.tripsLoading,
    transactionsReport: (state) => state.transactions,
    tmwReport: (state) => state.tmw_transactions,
    transactionTypes: (state) => state.transaction_types,
    mtTransactionTypes: (state) => state.manual_topup_transaction_types,
    orderTopupTransactionTypes: (state) => state.order_topup_transaction_types,
    lnHookStatus: (state) => state.ln_hook_status,
    tripsTimingSummary: (state) => state.trip_timing_summary,
    loadingDltimeSummary: (state) => state.loading_dltime_summary,
}

const actions = {
    async getTripsReport ({ commit } , options ) {
        // console.log(options);
        var now = moment()
        var roundEnd = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour');
        var roundStart = roundEnd.clone().subtract(2, 'hours');
        options = options ||  { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['order_created_at','order_no'],
            sortDesc: [true,false],
            filter: {
                trip_name: '',
                order_no: '',
                employee_no: '',
                ln_status: [],
                datetime_range: [roundStart,roundEnd],
                case: null
            },
        }
        
        const { sortBy, sortDesc, page, itemsPerPage, filter : option_filter } = options;
        
        let filter = lodash.cloneDeep(option_filter);
        
        var order = sortDesc.map(function (value) {
            return value === true ? 'DESC' : 'ASC';
        });
        var sort = sortBy.map(function (value) {
            return `${value}`;
        });
        
        var selected_lnstatus = null;
        if(filter.ln_status.length > 0){
            selected_lnstatus = filter.ln_status.map(function (item) {
                return `${item}`;
            });
        }
        var selected_case = null;
        if(filter.case){
            selected_case = `${filter.case}`;
        }
        var trip_date = null;
        if(filter.datetime_range && filter.datetime_range.length > 0){
            trip_date = [moment(filter.datetime_range[0],"DD-MM-YYYY HH:mm:ss"), moment(filter.datetime_range[1],"DD-MM-YYYY HH:mm:ss")];
            //console.log('filter time wt',trip_date);
        }
        else if(filter.date_range && filter.date_range.length > 0){
            trip_date = [filter.date_range[0], moment(filter.date_range[1]).add(1, 'day').format('YYYY-MM-DD')];
            //console.log('filter time od',trip_date);
            
        }

        let ZeekAggID = {id : parseInt(process.env.VUE_APP_ZEEKAGG_ID),name : 'ZEEKAGG'}
        let GrabID = {id : parseInt(process.env.VUE_APP_GRAB_ID),name : 'GRAB'}
        let PandagoID = {id : parseInt(process.env.VUE_APP_PANDAGO_ID),name : 'PANDAGO'}
        let LalamoveID = {id : parseInt(process.env.VUE_APP_LALAMOVE_ID),name : 'LALAMOVE'}

        let external_fleet = [ZeekAggID,GrabID,PandagoID,LalamoveID];

        if(!filter.fleet_name){
            filter.fleet_name = ''
        }

        if (Array.isArray(filter.employee_tier_id) && filter.employee_tier_id.length && filter.employee_tier_id.length > 0)
        {
            let check_some = external_fleet.some( r => filter.employee_tier_id.indexOf(r.id) >= 0);
            if(filter.employee_tier_id.length > 1 && check_some){
                let ex_fleet = external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).map(ob => {return ob.name}).join(',')
                if(external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).length == filter.employee_tier_id.length){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    delete filter.employee_tier_id;
                }else{
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    for(let i=0;i<external_fleet.length;i++){
                        const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                        if (index > -1) {
                            filter.employee_tier_id.splice(index, 1);
                        }
                    }
                }     
            }else{
                if(check_some){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name +','+ external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name : external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name
                }

                for(let i=0;i<external_fleet.length;i++){
                    const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                    if (index > -1) {
                        filter.employee_tier_id.splice(index, 1);
                    }
                }
            }
        }

        if(filter.employee_tier_id){
            filter.employee_tier_id = filter.employee_tier_id.join(',')
        }

        if(filter.fleet_name){
            filter.fleet_name = [...new Set(filter.fleet_name.split(","))].join(',')
        }else{
            delete filter.fleet_name
        }

        let param = {
            "_page" : page,
            "_limit" : itemsPerPage,
            "_sortby" : JSON.stringify(sort),
            "_order" : JSON.stringify(order),
            ...filter,
            ln_status : JSON.stringify(selected_lnstatus),
            case : JSON.stringify([selected_case]),
            daterange : JSON.stringify(trip_date)
        }

        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                //url: `/reports/trips?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trip_name ? `&trip_name=${filter.trip_name}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${selected_lnstatus ? `&ln_status=[${selected_lnstatus}]`: ''}${selected_case ? `&case=[${selected_case}]`: ''}${trip_date ? `&daterange=${trip_date}`: ''}`,
                url: `/reports/trips`,
                params : param,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            // console.log(config);
            // commit('SET_TRIPS_REPORT',[]);
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try{
                let { data } = await axios(config);
                 
                for (let i = 0 ; i < data.data.length ; i++)
                {
                    if(external_fleet.map(ob => {return ob.name}).includes(data.data[i].fleet_name)){
                        data.data[i].employee_tier_name = data.data[i].fleet_name
                    }
                }

                commit('SET_ALLOW_SEARCH', true);
                commit('SET_TRIPS_REPORT',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch(err){
                alert("Ajax Error " + err.status + ": " + err.message);
                commit('SET_ALLOW_SEARCH', true);
            }
        } else {
            router.push('/login');
        }
        
    },

    async checkOrderReconsign ( { commit }, options) {
        let order_no = options.order_no;

        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                url: `/order/${order_no}/reconciled_status`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_ALLOW_SEARCH', true);
                return data;
            } catch(err){
                alert("Ajax Error " + err.status + ": " + err.message);
                commit('SET_ALLOW_SEARCH', true);
                return false;
            }
        } else {
            router.push('/login');
        }

    },

    async attemptOrderReconsign ( { commit }, options) {
        let order_no = options.order_no;
        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'PUT',
                url: `/order/${order_no}/reconciled_attemp`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_ALLOW_SEARCH', true);
                return true;
            } catch(err){
                alert("Ajax Error " + err.status + ": " + err.message);
                commit('SET_ALLOW_SEARCH', true);
                return false;
            }
        } else {
            router.push('/login');
        }
    },
    
    async getTripsTimingReport ({ commit } , options ) {
        // console.log(options);
        var now = moment()
        var roundEnd = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour');
        var roundStart = roundEnd.clone().subtract(2, 'hours');
        options = options ||  { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['order_created_at','order_no'],
            sortDesc: [true,false],
            filter: {
                trip_name: '',
                order_no: '',
                employee_no: '',
                ln_status: [],
                datetime_range: [roundStart,roundEnd],
                case: null
            },
        }
        
        const { sortBy, sortDesc, page, itemsPerPage, filter : option_filter } = options;
        
        let filter = lodash.cloneDeep(option_filter);

        var order = sortDesc.map(function (value) {
            return value === true ? 'DESC' : 'ASC';
        });
        var sort = sortBy.map(function (value) {
            return `${value}`;
        });
        
        var selected_lnstatus = null;
        if(filter.ln_status.length > 0){
            selected_lnstatus = filter.ln_status.map(function (item) {
                return `${item}`;
            });
        }
        var selected_case = null;
        if(filter.case){
            selected_case = `${filter.case}`;
        }
        var trip_date = null;
        if(filter.datetime_range && filter.datetime_range.length > 0){
            trip_date = [moment(filter.datetime_range[0],"DD-MM-YYYY HH:mm:ss"), moment(filter.datetime_range[1],"DD-MM-YYYY HH:mm:ss")];
            //console.log('filter time wt',trip_date);
        }
        else if(filter.date_range && filter.date_range.length > 0){
            trip_date = [filter.date_range[0], moment(filter.date_range[1]).add(1, 'day').format('YYYY-MM-DD')];
            //console.log('filter time od',trip_date);
            
        }

        if (filter.order_start_type.length > 1 || filter.order_start_type.length == 0)
        {
            //select all
            delete filter.order_start_type;
        }
        else 
        {
            filter.order_start_type = filter.order_start_type[0];
        }

        if (filter.payment_type.length > 1 || filter.payment_type.length == 0)
        {
            //select all
            delete filter.payment_type;
        }
        else 
        {
            filter.payment_type = filter.payment_type[0];
        }

        let ZeekAggID = {id : parseInt(process.env.VUE_APP_ZEEKAGG_ID),name : 'ZEEKAGG'}
        let GrabID = {id : parseInt(process.env.VUE_APP_GRAB_ID),name : 'GRAB'}
        let PandagoID = {id : parseInt(process.env.VUE_APP_PANDAGO_ID),name : 'PANDAGO'}
        let LalamoveID = {id : parseInt(process.env.VUE_APP_LALAMOVE_ID),name : 'LALAMOVE'}

        let external_fleet = [ZeekAggID,GrabID,PandagoID,LalamoveID];

        filter.fleet_name = ''

        if (Array.isArray(filter.employee_tier_id) && filter.employee_tier_id.length && filter.employee_tier_id.length > 0)
        {
            let check_some = external_fleet.some( r => filter.employee_tier_id.indexOf(r.id) >= 0);
            if(filter.employee_tier_id.length > 1 && check_some){
                let ex_fleet = external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).map(ob => {return ob.name}).join(',')
                if(external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).length == filter.employee_tier_id.length){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    delete filter.employee_tier_id;
                }else{
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    for(let i=0;i<external_fleet.length;i++){
                        const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                        if (index > -1) {
                            filter.employee_tier_id.splice(index, 1);
                        }
                    }
                }     
            }else{
                if(check_some){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name +','+ external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name : external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name
                }

                for(let i=0;i<external_fleet.length;i++){
                    const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                    if (index > -1) {
                        filter.employee_tier_id.splice(index, 1);
                    }
                }
            }
        }

        if(filter.employee_tier_id){
            filter.employee_tier_id = filter.employee_tier_id.join(',')
        }

        if(filter.fleet_name){
            filter.fleet_name = [...new Set(filter.fleet_name.split(","))].join(',')
        }else{
            delete filter.fleet_name
        }

        console.log(filter.fleet_name)
        //filter.scope_order_start_type = "asap";
        filter.load_webhook = "1";
        let search_brand_id = [];
        let search_faas_brand_code = [];
        
        if (Array.isArray(filter.brand_id))
        {
            if (filter.brand_id.length > 0)
            {

                //console.log("filter.brand_id", filter.brand_id);
                search_brand_id = filter.brand_id.reduce( (r,b) => {
                    if (b.minor_brand_id)
                        r.push(b.minor_brand_id);
                    return r;
                }, [] );
                search_faas_brand_code = filter.brand_id.reduce( (r,b) => {
                    if (b.faas_brand_code)
                        r.push(b.faas_brand_code);
                    return r;
                }, [] );

                delete filter.brand_id;

                if (search_brand_id.length > 0)
                    filter.brand_id = search_brand_id.join(",");
                if (search_faas_brand_code.length > 0)
                    filter.faas_brand_code = search_faas_brand_code.join(",");

                //console.log("search_brand_id", search_brand_id);
                //console.log("search_faas_brand_code", search_faas_brand_code);
                
            }
            else
            {
                delete filter.brand_id;
            }
        }

        let param = {
            "_page" : page,
            "_limit" : itemsPerPage,
            "_sortby" : JSON.stringify(sort),
            "_order" : JSON.stringify(order),
            ...filter,
            ln_status : JSON.stringify(selected_lnstatus),
            case : JSON.stringify([selected_case]),
            daterange : JSON.stringify(trip_date)
        }

        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                //url: `/reports/trips?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trip_name ? `&trip_name=${filter.trip_name}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${selected_lnstatus ? `&ln_status=[${selected_lnstatus}]`: ''}${selected_case ? `&case=[${selected_case}]`: ''}${trip_date ? `&daterange=${trip_date}`: ''}`,
                url: `/reports/trips`,
                params : param,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            // console.log(config);
            // commit('SET_TRIPS_REPORT',[]);
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try{
                let { data } = await axios(config);

                for (let i = 0 ; i < data.data.length ; i++)
                {
                    if(external_fleet.map(ob => {return ob.name}).includes(data.data[i].fleet_name)){
                        data.data[i].employee_tier_name = data.data[i].fleet_name
                    }
                    // data.data[i] = calculateOrderTiming(data.data[i]);
                    data.data[i]['sub_total'] = parseInt(data.data[i]['gross_amount']) - parseInt(data.data[i]['discount_amount']);
                }
                // console.log(data);

                commit('SET_ALLOW_SEARCH', true);
                commit('SET_TRIPS_TIMING_REPORT', data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch(err){
                alert("Ajax Error " + err.status + ": " + err.message);
                commit('SET_ALLOW_SEARCH', true);
            }
        } else {
            router.push('/login');
        }
        
    },

    async getTripsTimingSummary ({ commit } , options ) {
        // console.log(options);

        if (state.loading_dltime_summary == true)
        {
            console.log("IGNORE getTripsTimingSummary the old one is fail or incomplete");
            return;
        }
        var now = moment()
        var roundEnd = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour');
        var roundStart = roundEnd.clone().subtract(2, 'hours');
        options = options ||  { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['order_created_at','order_no'],
            sortDesc: [true,false],
            filter: {
                trip_name: '',
                order_no: '',
                employee_no: '',
                ln_status: [],
                datetime_range: [roundStart,roundEnd],
                case: null
            },
        }
        
        const { sortBy, sortDesc, page, itemsPerPage, filter : option_filter } = options;
        
        let filter = lodash.cloneDeep(option_filter);

        var order = sortDesc.map(function (value) {
            return value === true ? 'DESC' : 'ASC';
        });
        var sort = sortBy.map(function (value) {
            return `${value}`;
        });
        
        var selected_lnstatus = null;
        if(filter.ln_status.length > 0){
            selected_lnstatus = filter.ln_status.map(function (item) {
                return `${item}`;
            });
        }
        var selected_case = null;
        if(filter.case){
            selected_case = `${filter.case}`;
        }
        var trip_date = null;
        if(filter.datetime_range && filter.datetime_range.length > 0){
            trip_date = [moment(filter.datetime_range[0],"DD-MM-YYYY HH:mm:ss"), moment(filter.datetime_range[1],"DD-MM-YYYY HH:mm:ss")];
            //console.log('filter time wt',trip_date);
        }
        else if(filter.date_range && filter.date_range.length > 0){
            trip_date = [filter.date_range[0], moment(filter.date_range[1]).add(1, 'day').format('YYYY-MM-DD')];
            //console.log('filter time od',trip_date);
            
        }

        if (filter.order_start_type.length > 1 || filter.order_start_type.length == 0)
        {
            //select all
            delete filter.order_start_type;
        }
        else 
        {
            filter.order_start_type = filter.order_start_type[0];
        }

        if (filter.payment_type.length > 1 || filter.payment_type.length == 0)
        {
            //select all
            delete filter.payment_type;
        }
        else 
        {
            filter.payment_type = filter.payment_type[0];
        }

        let ZeekAggID = {id : parseInt(process.env.VUE_APP_ZEEKAGG_ID),name : 'ZEEKAGG'}
        let GrabID = {id : parseInt(process.env.VUE_APP_GRAB_ID),name : 'GRAB'}
        let PandagoID = {id : parseInt(process.env.VUE_APP_PANDAGO_ID),name : 'PANDAGO'}
        let LalamoveID = {id : parseInt(process.env.VUE_APP_LALAMOVE_ID),name : 'LALAMOVE'}

        let external_fleet = [ZeekAggID,GrabID,PandagoID,LalamoveID];

        filter.fleet_name = ''

        if (Array.isArray(filter.employee_tier_id) && filter.employee_tier_id.length && filter.employee_tier_id.length > 0)
        {
            let check_some = external_fleet.some( r => filter.employee_tier_id.indexOf(r.id) >= 0);
            if(filter.employee_tier_id.length > 1 && check_some){
                let ex_fleet = external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).map(ob => {return ob.name}).join(',')
                if(external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).length == filter.employee_tier_id.length){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    delete filter.employee_tier_id;
                }else{
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    for(let i=0;i<external_fleet.length;i++){
                        const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                        if (index > -1) {
                            filter.employee_tier_id.splice(index, 1);
                        }
                    }
                }     
            }else{
                if(check_some){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name +','+ external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name : external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name
                }

                for(let i=0;i<external_fleet.length;i++){
                    const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                    if (index > -1) {
                        filter.employee_tier_id.splice(index, 1);
                    }
                }
            }
        }

        if(filter.employee_tier_id){
            filter.employee_tier_id = filter.employee_tier_id.join(',')
        }

        if(filter.fleet_name){
            filter.fleet_name = [...new Set(filter.fleet_name.split(","))].join(',')
        }else{
            delete filter.fleet_name
        }

        //filter.scope_order_start_type = "asap";
        filter.load_webhook = "1";
        let search_brand_id = [];
        let search_faas_brand_code = [];
        if (Array.isArray(filter.brand_id))
        {
            if (filter.brand_id.length > 0)
            {

                //console.log("filter.brand_id", filter.brand_id);
                search_brand_id = filter.brand_id.reduce( (r,b) => {
                    if (b.minor_brand_id)
                        r.push(b.minor_brand_id);
                    return r;
                }, [] );
                search_faas_brand_code = filter.brand_id.reduce( (r,b) => {
                    if (b.faas_brand_code)
                        r.push(b.faas_brand_code);
                    return r;
                }, [] );

                delete filter.brand_id;

                if (search_brand_id.length > 0)
                    filter.brand_id = search_brand_id.join(",");
                if (search_faas_brand_code.length > 0)
                    filter.faas_brand_code = search_faas_brand_code.join(",");

                //console.log("search_brand_id", search_brand_id);
                //console.log("search_faas_brand_code", search_faas_brand_code);
                
            }
            else
            {
                delete filter.brand_id;
            }
        }
        
        if (Array.isArray(filter.employee_tier_id))
        {
            if (filter.employee_tier_id.length > 0)
            {
                if(!external_fleet.some( r => filter.employee_tier_id.indexOf(r.id) >= 0)){
                    filter.employee_tier_id = filter.employee_tier_id.join(",");
                }else{
                    delete filter.employee_tier_id;
                }
            }
            else
            {
                delete filter.employee_tier_id;
            }
        }

        let param = {
            "_page" : page,
            "_limit" : itemsPerPage,
            "_sortby" : JSON.stringify(sort),
            "_order" : JSON.stringify(order),
            ...filter,
            ln_status : JSON.stringify(selected_lnstatus),
            // case : JSON.stringify([selected_case]),
            case : JSON.stringify(['skip_fixed_order']),//hard code skip fixed order
            daterange : JSON.stringify(trip_date)
        }

        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                //url: `/reports/trips?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trip_name ? `&trip_name=${filter.trip_name}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${selected_lnstatus ? `&ln_status=[${selected_lnstatus}]`: ''}${selected_case ? `&case=[${selected_case}]`: ''}${trip_date ? `&daterange=${trip_date}`: ''}`,
                url: `/reports/summary_dl_time`,
                params : param,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            // console.log(config);
            // commit('SET_TRIPS_REPORT',[]);
            commit('SET_LOADING_DLTIME_SUMMARY',true,{ root: true });
            try{
                let { data } = await axios(config);
                console.log("SET_TRIPS_TIMING_SUMMARY", data);

                commit('SET_ALLOW_SEARCH', true);
                commit('SET_TRIPS_TIMING_SUMMARY', data);
                commit('SET_LOADING_DLTIME_SUMMARY',false,{ root: true });
            } catch(err){
                //alert("Ajax Error " + err.status + ": " + err.message);
                console.log("Ajax Error " + err.status + ": " + err.message);
                commit('SET_LOADING_DLTIME_SUMMARY',true,{ root: true });
                commit('SET_ALLOW_SEARCH', true);
            }
        } else {
            router.push('/login');
        }
        
    },


    async loadTransTickets({ commit }, {item}){
        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                //url: `/reports/trips?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trip_name ? `&trip_name=${filter.trip_name}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${selected_lnstatus ? `&ln_status=[${selected_lnstatus}]`: ''}${selected_case ? `&case=[${selected_case}]`: ''}${trip_date ? `&daterange=${trip_date}`: ''}`,
                url: `/order/${item.order_no}/tran_zendesk`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            // console.log(config);
            // commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try{
                let { data } = await axios(config);
                 
                item.transactions = data.transactions
                item.tickets = data.tickets

                console.log(item.transactions, item.tickets)
                commit('SET_ALLOW_SEARCH', true);

            } catch(err){
                alert("Ajax Error " + err.status + ": " + err.message);
                commit('SET_ALLOW_SEARCH', true);
            }
        } else {
            router.push('/login');
        }
    },
    
    async exportTripsReport ({ commit } , options ) {
        console.log(options);
        options = options ||  { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['order_created_at','order_no'],
            sortDesc: [true,false],
            filter: {
                trip_name: '',
                order_no: '',
                employee_no: '',
                ln_status: [],
                date_range: [],
                datetime_range: [],
                case: null
            },
        }
        const { sortBy, sortDesc, page, itemsPerPage, filter : option_filter } = options;
        
        let filter = lodash.cloneDeep(option_filter);

        var order = sortDesc.map(function (value) {
            return value === true ? 'DESC' : 'ASC';
        });
        var sort = sortBy.map(function (value) {
            return `${value}`;
        });

        var selected_lnstatus = null;
        if(filter.ln_status.length > 0){
            selected_lnstatus = filter.ln_status.map(function (item) {
                return `${item}`;
            });
        }
        var selected_case = null;
        if(filter.case){
            selected_case = `${filter.case}`;
        }
        var trip_date = null;
        if(filter.date_range && filter.date_range.length > 0){
            console.log("date_range", filter.date_range)
            trip_date = [filter.date_range[0], filter.date_range[1]];
        }
        else if(filter.datetime_range && filter.datetime_range.length > 0){
            console.log("datetime_range", filter.datetime_range)
            if (typeof filter.datetime_range[0] == "string" )
                trip_date = [moment(filter.datetime_range[0],"DD-MM-YYYY HH:mm:ss"), moment(filter.datetime_range[1],"DD-MM-YYYY HH:mm:ss")];
            else
                trip_date = [filter.datetime_range[0], filter.datetime_range[1]];
        }

        let ZeekAggID = {id : parseInt(process.env.VUE_APP_ZEEKAGG_ID),name : 'ZEEKAGG'}
        let GrabID = {id : parseInt(process.env.VUE_APP_GRAB_ID),name : 'GRAB'}
        let PandagoID = {id : parseInt(process.env.VUE_APP_PANDAGO_ID),name : 'PANDAGO'}
        let LalamoveID = {id : parseInt(process.env.VUE_APP_LALAMOVE_ID),name : 'LALAMOVE'}

        let external_fleet = [ZeekAggID,GrabID,PandagoID,LalamoveID];

        if(!filter.fleet_name){
            filter.fleet_name = ''
        }

        if (Array.isArray(filter.employee_tier_id) && filter.employee_tier_id.length && filter.employee_tier_id.length > 0)
        {
            let check_some = external_fleet.some( r => filter.employee_tier_id.indexOf(r.id) >= 0);
            if(filter.employee_tier_id.length > 1 && check_some){
                let ex_fleet = external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).map(ob => {return ob.name}).join(',')
                if(external_fleet.filter(obj => {return filter.employee_tier_id.includes(obj.id) }).length == filter.employee_tier_id.length){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    delete filter.employee_tier_id;
                }else{
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet
                    for(let i=0;i<external_fleet.length;i++){
                        const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                        if (index > -1) {
                            filter.employee_tier_id.splice(index, 1);
                        }
                    }
                }     
            }else{
                if(check_some){
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name +','+ external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name : external_fleet.find(oj => {return oj.id == filter.employee_tier_id[0]}).name
                }

                for(let i=0;i<external_fleet.length;i++){
                    const index = filter.employee_tier_id.indexOf(external_fleet.map(o => o.id)[i]);
                    if (index > -1) {
                        filter.employee_tier_id.splice(index, 1);
                    }
                }
            }
        }

        if(filter.employee_tier_id){
            filter.employee_tier_id = filter.employee_tier_id.join(',')
        }

        if(filter.fleet_name){
            filter.fleet_name = [...new Set(filter.fleet_name.split(","))].join(',')
        }else{
            delete filter.fleet_name
        }

        console.log(JSON.stringify(trip_date));
        
        let param = {
            "_page" : 0,
            "_limit" : 10000,
            "_sortby" : JSON.stringify(sort),
            "_order" : JSON.stringify(order),
            ...filter,
            ln_status : JSON.stringify(selected_lnstatus),
            case : JSON.stringify([selected_case]),
            daterange : JSON.stringify(trip_date),
            is_export : 1
        }
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                //url: `/reports/trips?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trip_name ? `&trip_name=${filter.trip_name}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${selected_lnstatus ? `&ln_status=[${selected_lnstatus}]`: ''}${selected_case ? `&case=[${selected_case}]`: ''}${trip_date ? `&daterange=${trip_date}`: ''}`,
                url: `/reports/trips`,
                params : param,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                alert(data.message);
            } catch(err){
                console.log(err)
            }
        } else {
            router.push('/login');
        }
        
    },
    async exportTripsTimingReport ({ commit } , options ) {
        console.log(options);
        options = options ||  { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['order_created_at','order_no'],
            sortDesc: [true,false],
            filter: {
                trip_name: '',
                order_no: '',
                employee_no: '',
                ln_status: [],
                date_range: [],
                datetime_range: [],
                case: null
            },
        }
        const { sortBy, sortDesc, page, itemsPerPage, filter : option_filter } = options;
        
        let filter = lodash.cloneDeep(option_filter);
        var order = sortDesc.map(function (value) {
            return value === true ? 'DESC' : 'ASC';
        });
        var sort = sortBy.map(function (value) {
            return `${value}`;
        });

        var selected_lnstatus = null;
        if(filter.ln_status.length > 0){
            selected_lnstatus = filter.ln_status.map(function (item) {
                return `${item}`;
            });
        }
        var selected_case = null;
        if(filter.case){
            selected_case = `${filter.case}`;
        }
        var trip_date = null;
        if(filter.date_range && filter.date_range.length > 0){
            console.log("date_range", filter.date_range)
            trip_date = [filter.date_range[0], filter.date_range[1]];
        }
        else if(filter.datetime_range && filter.datetime_range.length > 0){
            console.log("datetime_range", filter.datetime_range)
            if (typeof filter.datetime_range[0] == "string" )
                trip_date = [moment(filter.datetime_range[0],"DD-MM-YYYY HH:mm:ss"), moment(filter.datetime_range[1],"DD-MM-YYYY HH:mm:ss")];
            else
                trip_date = [filter.datetime_range[0], filter.datetime_range[1]];
        }

        let ZeekAggID = {id : parseInt(process.env.VUE_APP_ZEEKAGG_ID),name : 'ZEEKAGG'}
        let GrabID = {id : parseInt(process.env.VUE_APP_GRAB_ID),name : 'GRAB'}
        let PandagoID = {id : parseInt(process.env.VUE_APP_PANDAGO_ID),name : 'PANDAGO'}
        let LalamoveID = {id : parseInt(process.env.VUE_APP_LALAMOVE_ID),name : 'LALAMOVE'}

        let external_fleet_id = [ZeekAggID,GrabID,PandagoID,LalamoveID];

        filter.fleet_name = ''

        if (Array.isArray(filter.employee_tier_id) && filter.employee_tier_id.length && filter.employee_tier_id.length > 0) {
            // Check if filter.employee_tier_id is an array, has length, and length is greater than 0

            let check_some = external_fleet_id.some(r => filter.employee_tier_id.includes(r.id));
            // Check if any employee_tier_id values match external_fleet_id

            if (filter.employee_tier_id.length > 1 && check_some) {
                // Multiple employee_tier_id values and some match external_fleet_id

                let ex_fleet = external_fleet_id.filter(obj => filter.employee_tier_id.includes(obj.id)).map(ob => ob.name).join(',');

                if (external_fleet_id.length === filter.employee_tier_id.length) {
                    // All employee_tier_id values match external_fleet_id

                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet;
                    delete filter.employee_tier_id;
                } else {
                    // Some employee_tier_id values match external_fleet_id

                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + ex_fleet : ex_fleet;
                    filter.employee_tier_id = filter.employee_tier_id.filter(id => !external_fleet_id.map(o => o.id).includes(id));
                    // Remove matched employee_tier_id values from the array
                }
            } else {
                // Single employee_tier_id value or none match external_fleet_id

                if (check_some) {
                    // Single employee_tier_id value matches external_fleet_id

                    const name = external_fleet_id.find(oj => oj.id === filter.employee_tier_id[0]).name;
                    filter.fleet_name = filter.fleet_name ? filter.fleet_name + ',' + name : name;
                }
                filter.employee_tier_id = filter.employee_tier_id.filter(id => !external_fleet_id.map(o => o.id).includes(id));
                // Remove matched employee_tier_id values from the array
            }
        }

        if (filter.employee_tier_id) {
            filter.employee_tier_id = filter.employee_tier_id.join(',');
        }

        if (filter.fleet_name) {
            filter.fleet_name = [...new Set(filter.fleet_name.split(','))].join(',');
            // Remove duplicate fleet names and join them with commas
        } else {
            delete filter.fleet_name;
            // Remove fleet_name property if it's falsy
        }          
        
        let search_brand_id = [];
        let search_faas_brand_code = [];
        if (Array.isArray(filter.brand_id))
        {
            if (filter.brand_id.length > 0)
            {

                //console.log("filter.brand_id", filter.brand_id);
                search_brand_id = filter.brand_id.reduce( (r,b) => {
                    if (b.minor_brand_id)
                        r.push(b.minor_brand_id);
                    return r;
                }, [] );
                search_faas_brand_code = filter.brand_id.reduce( (r,b) => {
                    if (b.faas_brand_code)
                        r.push(b.faas_brand_code);
                    return r;
                }, [] );

                delete filter.brand_id;

                if (search_brand_id.length > 0)
                    filter.brand_id = search_brand_id.join(",");
                if (search_faas_brand_code.length > 0)
                    filter.faas_brand_code = search_faas_brand_code.join(",");

                //console.log("search_brand_id", search_brand_id);
                //console.log("search_faas_brand_code", search_faas_brand_code);
                
            }
            else
            {
                delete filter.brand_id;
            }
        }
        
        if (filter.order_start_type.length > 1 || filter.order_start_type.length == 0)
        {
            //select all
            delete filter.order_start_type;
        }
        else 
        {
            filter.order_start_type = filter.order_start_type[0];
        }

        if (filter.payment_type.length > 1 || filter.payment_type.length == 0)
        {
            //select all
            delete filter.payment_type;
        }
        else 
        {
            filter.payment_type = filter.payment_type[0];
        }
        
        console.log(JSON.stringify(trip_date));
        
        let param = {
            "_page" : 0,
            "_limit" : 10000,
            "_sortby" : JSON.stringify(sort),
            "_order" : JSON.stringify(order),
            ...filter,
            ln_status : JSON.stringify(selected_lnstatus),
            case : JSON.stringify([selected_case]),
            daterange : JSON.stringify(trip_date),
            is_export : 2
        }

        console.log('final param')
        console.log(param)

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                //url: `/reports/trips?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trip_name ? `&trip_name=${filter.trip_name}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${selected_lnstatus ? `&ln_status=[${selected_lnstatus}]`: ''}${selected_case ? `&case=[${selected_case}]`: ''}${trip_date ? `&daterange=${trip_date}`: ''}`,
                url: `/reports/trips`,
                params : param,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                alert(data.message);
            } catch(err){
                console.log(err)
            }
        } else {
            router.push('/login');
        }
        
    },
    async getTransactionsReport ({ commit } , options ) {
        options = options || { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['transaction_date'],
            sortDesc: [true],
            filter: {
                trans_id: '',
                order_no: '',
                employee_no: '',
                transaction_type: [],
                transaction_date: [],
                transaction_status: null,
                wallet_code : ''
            }
        };
        const { sortBy, sortDesc, page, itemsPerPage, filter } = options;
        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });

        var selected_trantype = null;
        if(filter.transaction_type.length > 0){
            selected_trantype = filter.transaction_type.map(function (item) {
                return `"${item}"`;
            });
        }
        var transaction_date = null;
        if(filter.date_range.length > 0){
            transaction_date = `["${filter.date_range[0]}","${filter.date_range[1]}"]`;
        }
        commit('SET_ALLOW_SEARCH', false);
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                // url: `/reports/transactions?_page=${page}&_limit=${itemsPerPage}&_sortby=${sort}&_order=desc${filter.keyword ? `&keyword=${filter.keyword}`: ''}${filter.transaction_type ? `&transaction_type=${filter.transaction_type}`: ''}${filter.date_range.length > 0 ? `&tmw_transaction_date_from=${filter.date_range[0]}&&tmw_transaction_date_to=${filter.date_range[1]}`: ''}`,
                url: `/reports/transactions?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.trans_id ? `&trans_id=${filter.trans_id}`: ''}${filter.order_no ? `&order_no=${filter.order_no}`: ''}${filter.employee_no ? `&employee_no=${filter.employee_no}`: ''}${filter.transaction_status ? `&transaction_status=${filter.transaction_status}`: ''}${selected_trantype ? `&transaction_type=[${selected_trantype}]`: ''}${transaction_date ? `&transaction_date=${transaction_date}`: ''}${filter.wallet_code ? `&wallet_code=${filter.wallet_code}`: ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            // commit('SET_TRIPS_REPORT',[]);
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try{
                let { data } = await axios(config);
                commit('SET_ALLOW_SEARCH', true);
                commit('SET_TRANSACTIONS_REPORT',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch(err){
                console.log("Ajax Error",err);
                commit('SET_ALLOW_SEARCH', true);
            }
        } else {
            router.push('/login');
        }
        
    },

    async getTMWReport ({ commit } , options ) {
        options = options || { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['tmw_transaction_date'],
            sortDesc: [true],
            filter: {
                keyword: '',
                transaction_type: '',
                date_range: [],
            }
        };
        const { sortBy, sortDesc, page, itemsPerPage, filter } = options;
        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `/reports/tmw_transactions?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.keyword ? `&keyword=${filter.keyword}`: ''}${filter.transaction_type ? `&transaction_type=${filter.transaction_type}`: ''}${filter.wallet_code ? `&wallet_code=${filter.wallet_code}`: ''}${filter.date_range.length > 0 ? `&tmw_transaction_date_from=${filter.date_range[0]}&&tmw_transaction_date_to=${filter.date_range[1]}`: ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            // commit('SET_TRIPS_REPORT',[]);
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            try{
                let { data } = await axios(config);
                commit('SET_TMW_TRANSACTIONS_REPORT',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch(err){
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
        
    },

    async exportTMWReport ({ commit } , options ) {
        options = options || { 
            sortBy: ['tmw_transaction_date'],
            sortDesc: [true],
            filter: {
                keyword: '',
                transaction_type: '',
                date_range: [],
            }
        };
        const { sortBy, sortDesc, page, itemsPerPage, filter } = options;
        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `/reports/tmw_transactions/export?_sortby=[${sort}]&_order=[${order}]${filter.keyword ? `&keyword=${filter.keyword}`: ''}${filter.transaction_type ? `&transaction_type=${filter.transaction_type}`: ''}${filter.wallet_code ? `&wallet_code=${filter.wallet_code}`: ''}${filter.date_range.length > 0 ? `&tmw_transaction_date_from=${filter.date_range[0]}&&tmw_transaction_date_to=${filter.date_range[1]}`: ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                alert('โปรดรอซักครู่ ระบบจะทำการส่งลิงค์ให้คุณดาวน์โหลดข้อมูลผ่านทางอีเมล์ หลังจากการ export ข้อมูลเสร็จสิ้น')
            } catch(err){
                alert('Error '+ err.status + '  : ' + err.data.message);
            }
        } else {
            router.push('/login');
        }
        
    },

    async exportPowerBI ({ commit } , selected_date ) {
        if(!selected_date){
            alert('Please select corectly date to export');
            return true;
        }
        const params = {
            "tmw_report_date" : selected_date
        };

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `/transaction/dump_tmw_report_sftp`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                alert('ระบบเริ่มดำเนินการ export ข้อมูลของวันที่ ' + moment(selected_date).format('DD-MM-YYYY') + ' ไปยัง Power BI');
            } catch(err){
                alert('Error '+ err.status + '  : ' + err.data.message);
            }
        } else {
            router.push('/login');
        }
        
    },

    async getTransactionTypes ({ commit } ) {

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `/reports/transaction_type/`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_TRANSACTIONS_TYPES',data);
            } catch(err){
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
        
    },

    async importTMW ({ dispatch, commit }, formData) {
        commit('SET_LOADING',true,{ root: true });
        const base64_result = await helpers.toBase64(formData.import_file).catch(e => Error(e));
        if(base64_result instanceof Error) {
            console.log('Error: ', base64_result.message);
            return;
        } else {
            // console.log('Base64',base64_result.split(",")[1]);
        }

        //** Prepare to post data */
        const params = {
            'file': base64_result.split(",")[1],
            'replace': formData.replace,
        };
        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `/transaction/add_tmw_report`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                alert("TMW import data success with " + response.data.count + "rows")
                dispatch('getTMWReport');
                commit('SET_LOADING',false,{ root: true });
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    // case 422:
                    //     break;
                    // case 423:
                    //     alert("Incentive topups program on your selected date is existing.");
                    //     break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },
}

const mutations = {
    SET_TRIPS_REPORT (state, payload) {
        state.trips = payload
    },
    SET_TRIPS_TIMING_REPORT (state, payload) {
        state.trips_timing = payload
    },
    SET_TRANSACTIONS_REPORT (state, payload) {
        state.transactions = payload
    },
    SET_TMW_TRANSACTIONS_REPORT (state, payload) {
        state.tmw_transactions = payload
    },
    SET_TRANSACTIONS_TYPES (state, payload) {
        state.transaction_types = payload
    },
    SET_TRIPS_LOADING (state, payload) {
        state.tripsLoading = payload
    },

    SET_LOADING_DLTIME_SUMMARY (state, payload) {
        state.loading_dltime_summary = payload
    },

    SET_TRIPS_TIMING_SUMMARY (state, payload) {
        state.trip_timing_summary = payload
    },
}

//Custom function --------
const calculateOrderTiming = (order) => {
   
    let current_code = 9001;


    let timing = {
        order_created_at : null,
        rider_accept_at : null,
        rider_in_nearby_store_at : null,
        rider_leave_store_at : null,
        order_delivered_at : null,
        match_time : null,
        travel_time : null,
        wait_time : null,
        instore_time : null,
        drive_time : null,
        delivery_time : null
    };

    if (order.fleet_name.trim() !== "ZEEK" && order.fleet_name.trim() !== "MFG") {

        return { ...order, timing : timing };
    }

    //find first created at
    let webhooks = order.webhook;
    if (Array.isArray(webhooks) == false)
        webhooks = [];

    for (let idx = webhooks.length-1 ; idx >= 0 ; idx--)
    {
        if (webhooks[idx].fleet_raw_data && typeof webhooks[idx].fleet_raw_data.order_status != 'undefined')
        {
            current_code = webhooks[idx].fleet_raw_data.order_status;
            idx = -1;
        }
    }

    if (current_code == 9025)
    {
        current_code = 9001; //cancel case use created instead
    }

    let orderCreated = process.env.VUE_APP_ZEEK_CREATED_WEBHOOK_NAME.split(',')
    let order_created_webhook = ''
    for(let i = 0; i < orderCreated.length; i++){ 
        // let getOrderCreated = lodash.find(webhooks, { fleet_name : "ZEEK", status : orderCreated[i] })
        let getOrderCreated = lodash.reduce( webhooks, ( r, w ) => { 
            if ( (w.fleet_name == 'ZEEK' || w.fleet_name == 'MFG') && w.status == orderCreated[i] )
            {
                if (r == null)
                    return w
                else if (dayjs(w.updated_on) < dayjs(r.updated_on))
                    return w;
                else
                    return r;
            }
            else
                return r;
        }, null )

        if(getOrderCreated){
            order_created_webhook  = getOrderCreated;
            break;
        }
    }

    let riderAccept = process.env.VUE_APP_ZEEK_ACCEPT_WEBHOOK_NAME.split(',')
    let rider_accept_webhook = ''
    for(let i = 0; i < riderAccept.length; i++){ 
        // let getRiderAccept= lodash.find(webhooks, { fleet_name : "ZEEK", status : riderAccept[i] })
        let getRiderAccept = lodash.reduce( webhooks, ( r, w ) => { 
            if ( (w.fleet_name == 'ZEEK' || w.fleet_name == 'MFG') && w.status == riderAccept[i] )
            {
                if (r == null)
                    return w
                else if (dayjs(w.updated_on) > dayjs(r.updated_on))
                    return w;
                else
                    return r;
            }
            else
                return r;
        }, null );
        if(getRiderAccept){
            rider_accept_webhook = getRiderAccept;
            break;
        }
    }

    let nearbyStore = process.env.VUE_APP_ZEEK_NEARBY_STORE_WEBHOOK_NAME.split(',')
    let rider_in_nearby_store_webhook = ''
    for(let i = 0; i < nearbyStore.length; i++){ 
        // let getNearBy = lodash.find(webhooks, { fleet_name : "ZEEK", status : nearbyStore[i] })
        let getNearBy = lodash.reduce( webhooks, ( r, w ) => { 
            if ( (w.fleet_name == 'ZEEK' || w.fleet_name == 'MFG') && w.status == nearbyStore[i] )
            {
                if (r == null)
                    return w
                else if (dayjs(w.updated_on) > dayjs(r.updated_on))
                    return w;
                else
                    return r;
            }
            else
                return r;
        }, null );
        if(getNearBy){
            rider_in_nearby_store_webhook = getNearBy;
            break;
        }
    }

    let riderLeave = process.env.VUE_APP_ZEEK_LEAVE_STORE_WEBHOOK_NAME.split(',')
    let rider_leave_store_webhook = ''
    for(let i = 0; i < riderLeave.length; i++){ 
        // let getRiderLeave = lodash.find(webhooks, { fleet_name : "ZEEK", status : riderLeave[i] })
        let getRiderLeave = lodash.reduce( webhooks, ( r, w ) => { 
            if ( (w.fleet_name == 'ZEEK' || w.fleet_name == 'MFG') && w.status == riderLeave[i] )
            {
                if (r == null)
                    return w
                else if (dayjs(w.updated_on) > dayjs(r.updated_on))
                    return w;
                else
                    return r;
            }
            else
                return r;
        }, null );
        if(getRiderLeave){
            rider_leave_store_webhook = getRiderLeave;
            break;
        }
    }

    let orderDelivery = process.env.VUE_APP_ZEEK_DELIVERED_WEBHOOK_NAME.split(',')
    let order_delivered_webhook = ''
    for(let i = 0; i < orderDelivery.length; i++){ 
        // let getOrderDelivery = lodash.find(webhooks, { fleet_name : "ZEEK", status : orderDelivery[i] })
        let getOrderDelivery = lodash.reduce( webhooks, ( r, w ) => { 
            if ( (w.fleet_name == 'ZEEK' || w.fleet_name == 'MFG') && w.status == orderDelivery[i] )
            {
                if (r == null)
                    return w;
                else if (dayjs(w.updated_on) > dayjs(r.updated_on))
                    return w;
                else
                    return r;
            }
            else
                return r;
        }, null );
        if (getOrderDelivery)
        {
            order_delivered_webhook = getOrderDelivery;
            break;
        }
    }

    if (order_created_webhook)
        timing.order_created_at = moment(order_created_webhook.updated_on);
    if (rider_accept_webhook)
        timing.rider_accept_at = moment(rider_accept_webhook.updated_on);
    if (rider_in_nearby_store_webhook)
        timing.rider_in_nearby_store_at = moment(rider_in_nearby_store_webhook.updated_on);
    if (rider_leave_store_webhook)
        timing.rider_leave_store_at = moment(rider_leave_store_webhook.updated_on);
    if (order_delivered_webhook)
        timing.order_delivered_at = moment(order_delivered_webhook.updated_on);


    if (process.env.VUE_APP_NODE_ENV == 'development')
    {
        console.log("order.order_no", order.order_no);
        console.log("webhooks", webhooks);
        console.log("timing", timing);
    }
    // let checkTime = rider_in_nearby_store_webhook ? rider_in_nearby_store_webhook : order_delivered_webhook ? order_delivered_webhook : null
    // if(checkTime){
    //     console.log(webhooks)
        // if(checkTime.updated_on){
        //     const currentTime = moment()
        //     let duration = moment.duration(currentTime.diff(moment(checkTime.updated_on)));
        //     let minuteDiff = duration.asMinutes();
        //     if(minuteDiff > 30){
        //         console.log('dd')
        //     }else{
        //         console.log('not late')
        //     }
        // }
    // }else{
    //     console.log('not late')
    // }
    
    if (timing.order_created_at && timing.order_created_at.isValid() && timing.rider_accept_at && timing.rider_accept_at.isValid())
    {
        timing.match_time = timing.rider_accept_at.diff(timing.order_created_at, 'seconds');
    }
    else if (current_code >= process.env.VUE_APP_ZEEK_ACCEPT_WEBHOOK_CODE)
    {
        timing.match_time = 0;
    }

    if (timing.rider_accept_at && timing.rider_accept_at.isValid() && timing.rider_in_nearby_store_at && timing.rider_in_nearby_store_at.isValid())
    {
        timing.travel_time = timing.rider_in_nearby_store_at.diff(timing.rider_accept_at, 'seconds');
    }
    else if (current_code >= process.env.VUE_APP_ZEEK_NEARBY_STORE_WEBHOOK_CODE)
    {
        timing.travel_time = 0;
    }
    
    if (timing.rider_in_nearby_store_at && timing.rider_in_nearby_store_at.isValid() && timing.rider_leave_store_at && timing.rider_leave_store_at.isValid())
    {
        timing.wait_time = timing.rider_leave_store_at.diff(timing.rider_in_nearby_store_at, 'seconds');
    }
    else if (current_code >= process.env.VUE_APP_ZEEK_LEAVE_STORE_WEBHOOK_CODE)
    {
        timing.wait_time = 0;
    }

    /*
    if (timing.order_created_at && timing.order_created_at.isValid() && timing.rider_leave_store_at && timing.rider_leave_store_at.isValid())
    {
        timing.instore_time = timing.rider_leave_store_at.diff(timing.order_created_at, 'seconds');
    }
    */

    if (timing.rider_leave_store_at && timing.rider_leave_store_at.isValid() && timing.order_created_at && timing.order_created_at.isValid())
    {
        //9015 - 9001
        timing.instore_time =  timing.rider_leave_store_at.diff(timing.order_created_at, 'seconds');
    }

    if (timing.rider_leave_store_at && timing.rider_leave_store_at.isValid() &&  timing.order_delivered_at &&  timing.order_delivered_at.isValid())
    {
        //9021 - 9015
        timing.drive_time = timing.order_delivered_at.diff(timing.rider_leave_store_at, 'seconds');
    }
    else if (current_code >= process.env.VUE_APP_ZEEK_DELIVERED_WEBHOOK_CODE)
    {
        timing.drive_time = 0;
    }

    /*
    if (timing.order_created_at && timing.order_created_at.isValid() && timing.order_delivered_at && timing.order_delivered_at.isValid())
    {
        timing.delivery_time = timing.order_delivered_at.diff(timing.order_created_at, 'seconds');
    }
    */

    if (timing.order_delivered_at && timing.order_delivered_at.isValid() && timing.order_created_at && timing.order_created_at.isValid())
    {
        //9021 - 9001
        timing.delivery_time = timing.order_delivered_at.diff(timing.order_created_at, 'seconds')
    }

    return { ...order, timing };

}

export default {
    state,
    getters,
    actions,
    mutations,
};

