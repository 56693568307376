import axios from 'axios';
import router from '@/routes';
import helpers from '@/helpers'
import moment from 'moment';

export const actions = {
    async getDrivers ({ commit } ,  options) {
        options = options || { 
            page: 1,
            itemsPerPage: 25,
            sortBy: ['updated_at'],
            sortDesc: [true],
            multiSort: true,
            mustSort: true,
            filter: {
              keyword: '',
              idcard:'',
              province: null,
              zone: null,
              approve_step: null,
              tier: null,
              equipments:[],
              category_tag:[],
              deposit_status: null,
              status: null
            }
        };
        const { type, sortBy, sortDesc, page, itemsPerPage, filter } = options;

        var approve_step_id = null;
        if(filter.approve_step){
            approve_step_id = `[${filter.approve_step}]`;
        } else{
            approve_step_id = '[6,7,8,9]';
        }

        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });

        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);

            const config = { 
                method: 'GET',
                url: `employee?_page=${page}&_limit=${itemsPerPage}&_sortby=[${sort}]&_order=[${order}]${filter.keyword ? `&keyword=${filter.keyword}`: ''}${filter.idcard ? `&ssn=${filter.idcard}`: ''}${filter.province ? `&province_id=${filter.province.value}`: ''}${filter.zone ? `&zone=${filter.zone.value}`: ''}${approve_step_id ? `&approve_step_id=${approve_step_id}`: ''}${filter.tier ? `&tier=${filter.tier}`: ''}${filter.equipments && filter.equipments.length > 0 ? `&equipments=[${filter.equipments}]`: ''}${filter.category_tag && filter.category_tag.length > 0 ? `&category_tag=${JSON.stringify(filter.category_tag)}`: ''}${filter.deposit_status != null ? `&deposit_status=${filter.deposit_status}`: ''}${filter.status ? `&status=${filter.status}`: ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING_DATA_TABLE',true,{ root: true });
            commit('SET_DRIVERS',[]);
            try{
                let { data } = await axios(config);
                commit('SET_ALLOW_SEARCH', true);
                commit('SET_DRIVERS',data);
                commit('SET_LOADING_DATA_TABLE',false,{ root: true });
            } catch(err){
                console.log("Ajax Error",err);
                commit('SET_ALLOW_SEARCH', true);

            }
        } else {
            router.push('/login');
        }
    },
    
    async getDriverDetails ({ commit, dispatch }, id) {
        // commit('RESET_PROFILE_IMG');
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee/${id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                
                let { data } = await axios(config);
                commit('SET_DRIVER_DETAILS',data);

                dispatch('getEvidences',id);
            } catch(err){
                console.log("Ajax Error",err);

            }
        } else {
            router.push('/login');
        }
    },
    async getProvinces ({ commit }) {
        const token = helpers.getToken();
        const config = { 
            method: 'GET',
            url: `employee/province`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",
            }
        };
        try{
            var axios_instance = axios.create();
            delete axios_instance.defaults.headers.common['Authorization'];
            delete axios_instance.defaults.headers.common['AUTH-TYPE'];
            let { data } = await axios_instance(config);

            var mapOptions = [];
            data.data.forEach(element => {
                mapOptions.push({value: element.ProvCode ,text: element.ProvName_TH})
            });
            commit('SET_PROVINCES',mapOptions);
            commit('SET_PROVINCES_ARRAY',data.data);
        } catch(err){
            console.log("Ajax Error",err);
        }
        

    },
    async getZones ({ commit } , prov) {
        const config = { 
            method: 'GET',
            url: `https://api.1112delivery.com/api/v1/districts/th/${prov}` ,
        };
        try{
            //let { data } = await axios(config);
            var axios_instance = axios.create();
            delete axios_instance.defaults.headers.common['Authorization'];
            delete axios_instance.defaults.headers.common['AUTH-TYPE'];
            let { data } = await axios_instance(config);
            
            var mapOptions = [];
            data.data.forEach(element => {
                mapOptions.push({value: prov + element.DistCode ,text: element.DistName_TH})
            });
            commit('SET_ZONES',mapOptions);
        } catch(err){
            console.log("Ajax Error",err);
        }
    },
    clearZones ({commit}){
        commit('SET_ZONES', []);
    },
    async getEquipments ({ commit }) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `equipment?search_all=1`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                if(data){
                    data.forEach(function(item){
                        item.text = item.name + ' [' + item.sku_no +']';
                    });
                    commit('SET_EQUIPMENTS',data);
                } else {
                    commit('SET_EQUIPMENTS',[]);
                }
            } catch(err){
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },
    async getEmployeeEquipments ({ commit }, employee) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee_equipment/${employee.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try{
                let { data } = await axios(config);
                if(data){
                    commit('SET_EMPLOYEE_EQUIPMENTS',data);
                } else {
                    commit('SET_EMPLOYEE_EQUIPMENTS',[]);
                }
            } catch(err){
                console.log("Ajax Error",err);
            }
        } else {
            router.push('/login');
        }
    },
    async addEmployeeEquipments ({ commit }, data) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data:{
                    equipment_id : data.equipment_id,
                    delivery_note : data.delivery_note,
                    ...(data.equipment_payment_profile_id ? { equipment_payment_profile_id : data.equipment_payment_profile_id } : {} )
                },
                url: `employee_equipment/${data.employee_id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try{
                let { data } = await axios(config);
                return data;
            } catch(err){
                console.log("Ajax Error",err);
                return false;
            }
        } else {
            router.push('/login');
        }
    },
    async deleteEmployeeEquipments ({ commit }, data) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `employee_equipment/${data.employee_id}/${data.employee_equipment_id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try{
                let { data } = await axios(config);
                return data;
            } catch(err){
                console.log("Ajax Error",err);
                return false;
            }
        } else {
            router.push('/login');
        }
    },
    async addEmployeeEquipmentTransaction ({ commit }, data) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data:{
                    paid_amount : data.paid_amount,
                    attachments : data.attachments
                },
                url: `employee_equipment/${data.employee_id}/${data.employee_equipment_id}/transaction`,

                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try{
                let { data } = await axios(config);
                return data;
            } catch(err){
                console.log("Ajax Error",err);
                return false;
            }
        } else {
            router.push('/login');
        }
    },
    async listEmployeeEquipmentTransaction ({ commit }, data) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee_equipment/${data.employee_id}/${data.employee_equipment_id}/transaction`,

                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };

            try{
                let { data : response_data } = await axios(config);

                //sort response_data
                response_data.sort( (a, b) => {
                    
                    if (a.term_no != b.term_no)
                        return a.term_no - b.term_no
                    return moment(a.createdAt).unix() - moment(b.createdAt).unix()
                })



                if (data.equipment_amount)
                {
                    let remain = data.equipment_amount;
                    for (let i = 0 ; i < response_data.length ; i++)
                    {
                        response_data[i].remain_amount = remain - response_data[i].amount;
                        remain = response_data[i].remain_amount;
                    }
                }
                return response_data;
            } catch(err){
                console.log("Ajax Error",err);
                return false;
            }
        } else {
            router.push('/login');
        }
    },
    async updateDriver ({ commit, dispatch }, driver) {
        const params = {
            'driver-name' : driver.name,
            'driver-email' : driver.email,
            'driver-phone': driver.phone,
            'driver-idcard' : driver.idcard,
            'site-id': driver.province.value,
            'driver-site-name': driver.selected_site,
            'driver-tier': driver.tier,
            'status_id' : driver.status,
            'equipments' : driver.equipments,
            'deposit_status' : driver.deposit_status,
            'category_tags' : driver.categories,
            'store_uid' : driver.store_uid ? driver.store_uid : null
        };

        const token = helpers.getToken();
        if(token)
        {
            const config = { 
                method: 'PUT',
                data: params,
                url: `employee/${driver.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_SAVING',true,{ root: true });
            try{
                let { data } = await axios(config);
                commit('SET_SAVING',false,{ root: true });
                dispatch('setAlert',{ status: true, message: 'Data has been saved successfully.' });
                dispatch('getDriverDetails', driver.id);
                // router.push('/drivers');
            } catch(err){
                commit('SET_SAVING',false,{ root: true });
                console.log("Ajax Error",err);
                alert("Error " + err.status + ":  " + err.data.message);
            }
        } else {
            router.push('/login');
        }
    },
    async syncDriver ({ commit, dispatch }, driver) {

        const token = helpers.getToken();
        if(token)
        {
            const config = { 
                method: 'PUT',
                url: `employee/${driver.id}/sync_ln_status`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_SAVING',true,{ root: true });
            try{
                let { data } = await axios(config);
                commit('SET_SAVING',false,{ root: true });
                dispatch('setAlert',{ status: true, message: data.message });
                dispatch('getDriverDetails', driver.id);
                // router.push('/drivers');
            } catch(err){
                commit('SET_SAVING',false,{ root: true });
                console.log("Ajax Error",err);
                alert("Error " + err.status + ":  " + err.data.message);
            }
        } else {
            router.push('/login');
        }
    },
    async deleteDriver ({ commit, dispatch }, driver) {

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'DELETE',
                url: `employee/${driver.id}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_SAVING',true,{ root: true });
            try{
                let { data } = await axios(config);
                commit('SET_SAVING',false,{ root: true });
                router.push(driver.redirect_uri);
            } catch(err){
                commit('SET_SAVING',false,{ root: true });
                console.log("Ajax Error",err);
                alert("Error " + err.status + ":  " + err.data.message);
            }
        } else {
            router.push('/login');
        }
    },

    async getDriverTripsReport ({ commit }, req) {
        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                url: `employee/reports/trips/${req.id}?date_from=${req.filterDate[0]}&date_to=${req.filterDate[1]}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_DRIVER_TRIPS_REPORT',data);
                commit('SET_ALLOW_SEARCH', true);

            } catch(err){
                console.log("Ajax Error",err);
                commit('SET_ALLOW_SEARCH', true);

                // alert("Error " + err.status);
            }
        } else {
            router.push('/login');
        }
    },
    async getDriverTransactionsReport ({ commit }, req) {
        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);

            const config = { 
                method: 'GET',
                url: `employee/reports/transactions/${req.id}?date_from=${req.filterDate[0]}&date_to=${req.filterDate[1]}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_DRIVER_TRANSACTIONS_REPORT',data);
                commit('SET_ALLOW_SEARCH', true);

            } catch(err){
                console.log("Ajax Error",err);
                commit('SET_ALLOW_SEARCH', true);

                // alert("Error " + err.status);
            }
        } else {
            router.push('/login');
        }
    },
    async getDriverRaitingsReport ({ commit }, req) {
        const token = helpers.getToken();
        if(token){
            commit('SET_ALLOW_SEARCH', false);
            const config = { 
                method: 'GET',
                url: `employee/reports/ratings/${req.id}?date_from=${req.filterDate[0]}&date_to=${req.filterDate[1]}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                commit('SET_DRIVER_RAITINGS_REPORT',data);
                commit('SET_ALLOW_SEARCH', true);

            } catch(err){
                // console.log("Ajax Error",err);
                alert("Error " + err.status);
                commit('SET_ALLOW_SEARCH', true);

            }
        } else {
            router.push('/login');
        }
    },
    async getDriverChangeLog ({ commit }, req) {
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `employee/${req.id}/change_log`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            try{
                let { data } = await axios(config);
                return data;
            } catch(err){
                console.log("Ajax Error",err);

                // alert("Error " + err.status);
            }
        } else {
            router.push('/login');
        }
    },
    
    async exportEmployees({ commit } , options ) {
        options = options || { 
            type: 'driver',
            page: 1,
            itemsPerPage: 25,
            sortBy: ['updated_at'],
            sortDesc: [true],
            multiSort: true,
            mustSort: true,
            filter: {
                approve_step: null,
                keyword: '',
                idcard: '',
                training_date: undefined,
                province: null,
                zone: null,
                tier: null,
                equipments:[],
                deposit_status: null,
                status: null
            }
        };
        const { sortBy, sortDesc, page, itemsPerPage, filter } = options;
        var order = sortDesc.map(function (value) {
            return value === true ? '"DESC"' : '"ASC"';
        });
        var sort = sortBy.map(function (value) {
            return `"${value}"`;
        });

        var approve_step_id = null;
        switch(options.type){
            case 'lead':
                approve_step_id = '[1,2]';
            break;
            case 'onboard':
                approve_step_id = '[3,4,5]';
            break;
            case 'driver':
                approve_step_id = '[6,7,8]';
            break;
        }
        commit('SET_ALLOW_SEARCH', false);

        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'GET',
                url: `/export/employee?employee_type=${options.type}${filter.approve_step ? `&approve_step_id=${filter.approve_step}`: `&approve_step_id=${approve_step_id}`}&_sortby=[${sort}]&_order=[${order}]${filter.keyword ? `&keyword=${filter.keyword}`: ''}${filter.idcard ? `&ssn=${filter.idcard}`: ''}${filter.training_date ? `&training_schedule_id=[${filter.training_date.id}]`: ''}${filter.province ? `&province_id=${filter.province.value}`: ''}${filter.zone ? `&zone=${filter.zone.value}`: ''}${filter.tier ? `&tier=${filter.tier}`: ''}${filter.equipments && filter.equipments.length > 0 ? `&equipments=[${filter.equipments}]`: ''}${filter.deposit_status ? `&deposit_status=${filter.deposit_status}`: ''}${filter.status ? `&status=${filter.status}`: ''}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            commit('SET_LOADING',true,{ root: true });
            // console.log(config);
            try{
                let { data } = await axios(config);
                commit('SET_LOADING',false,{ root: true });
                alert('โปรดรอซักครู่ ระบบจะทำการส่งลิงค์ให้คุณดาวน์โหลดข้อมูลผ่านทางอีเมล์ หลังจากการ export ข้อมูลเสร็จสิ้น')
                commit('SET_ALLOW_SEARCH', true);

            } catch(err){
                alert('Error '+ err.status + '  : ' + err.data.message);
                commit('SET_ALLOW_SEARCH', true);

            }
        } else {
            router.push('/login');
        }
        
    },

    async ImportEmployeeData({ dispatch, commit }, formData) {
        commit('SET_LOADING',true,{ root: true });
        const base64_result = await helpers.toBase64(formData.import_file).catch(e => Error(e));
        if(base64_result instanceof Error) {
            console.log('Error: ', base64_result.message);
            alert('Something went wrong during upload file.');
            return;
        } else {
            // console.log('Base64',base64_result.split(",")[1]);
        }

        //** Prepare to post data */
        const params = {
            'file': base64_result.split(",")[1],
            // 'replace': formData.replace,
        };
        
        const token = helpers.getToken();
        if(token){
            const config = { 
                method: 'POST',
                data: JSON.stringify(params),
                url: `employee/import/update_data`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                }
            };
            axios(config)
            .then(response => { 
                alert("The system is going to proceed updating data, and will send you an email of report.")
                commit('SET_LOADING',false,{ root: true });
            })
            .catch(error => {
                commit('SET_LOADING',false,{ root: true });
                switch(error.status){
                    // case 422:
                    //     break;
                    // case 423:
                    //     alert("Incentive topups program on your selected date is existing.");
                    //     break;
                    default:
                        alert('Error '+ error.status + '  : ' + error.data.message);
                        break;
                }
            });
        } else {
            router.push('/login');
        }
    },
};
