import Vue from 'vue';
import Vuex from 'vuex';
import App from './modules/app';
import Auth from './modules/auth';
import Users from './modules/users';
import Trainings from './modules/trainings';
import Categories from './modules/categories';
import Tiers from './modules/driver_tiers';
import DriverApproval from './modules/approve_driver';
import Drivers from './modules/drivers';
import DriverImages from './modules/driver_images';
import Incentives from './modules/incentives';
import TransactionWallets from './modules/transaction_wallets';
import Reports from './modules/reports';
import Variables from './modules/variables';
import Settings from './modules/settings';
import Dashboard from './modules/dashboard';
import FulltimeRider from './modules/fulltime_rider';
import Manpower from './modules/manpower';

Vue.use(Vuex);
Vue.config.devtools = true

Vue.config.devtools = true

export default new Vuex.Store({

  modules: {
    App,
    Auth,
    Users,
    Trainings,
    Categories,
    Tiers,
    DriverApproval,
    Drivers,
    DriverImages,
    Incentives,
    TransactionWallets,
    Reports,
    Variables,
    Settings,
    Dashboard,
    FulltimeRider,
    Manpower
  }
});